@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/FiraSans/FiraSans-Thin.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/FiraSans/FiraSans-ThinItalic.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/FiraSans/FiraSans-UltraLight.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/FiraSans/FiraSans-UltraLightItalic.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/FiraSans/FiraSans-ExtraLight.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/FiraSans/FiraSans-ExtraLightItalic.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/FiraSans/FiraSans-Light.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/FiraSans/FiraSans-LightItalic.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/FiraSans/FiraSans-Regular.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/FiraSans/FiraSans-Italic.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/FiraSans/FiraSans-Medium.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/FiraSans/FiraSans-MediumItalic.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/FiraSans/FiraSans-SemiBold.otf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/FiraSans/FiraSans-SemiBoldItalic.otf');
}
