#console > article {
  padding-right: 0 !important;
}

// modal size
.restform-modal {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .restform-modal {
    max-width: 390px !important;
  }
}
@media (min-width: 768px) {
  .restform-modal {
    max-width: 570px !important;
  }
}
@media (min-width: 992px) {
  .restform-modal {
    max-width: 810px !important;
  }
}
@media (min-width: 1200px) {
  .restform-modal {
    max-width: 990px !important;
  }
}

// main
.restform {
  background: $white;
  border-radius: 0;
  position: relative;
}
.restform > section {
  position: relative;
}
.restform-nav,
.restform > section > header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.restform > section > div {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
// fix text color
.restform .text-secondary {
  color: $gray-700 !important;
}

// sticky header
.restform-nav {
  background: $gray-100;
  z-index: 2001;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.restform-nav span {
  color: $purple;
  font-weight: 600;
  padding-left: .7rem;
  padding-right: .7rem !important;
}
.restform-nav input {
  font-weight: 500;
}
.restform-nav button {
  height: calc(2.53125rem + 4px);
  font-size: 12px;
}
// close button
.restform-nav .btn-light {
  padding-top: 10px;
  padding-left: 14px;
  padding-right: 12px;
}

// header bar
.restform-header {
  background: $gray-100;
  border-style: solid;
  border-width: 1px 0 3px;
  border-color: $gray-200;
  color: $gray-600;
}
// status code
.restform-header .badge {
  font-weight: 500;
}

// API endpoint
.restform-endpoint {
  background: $purple;
  color: $gray-200;
  font-family: $font-family-monospace;
  display: inline-block;
  font-size: 90%;
  padding: .2rem .8rem;
  border-radius: .3rem;
}
// highlight API endpoint params
.restform-endpoint > strong {
  color: $white;
}
.restform-endpoint > strong > span {
  color: $yellow;
}
@media (max-width: 991px) {
  .restform-endpoint {
    margin-top: 5px;
  }
}

// switch hosts
.restform-header a {
  background: lighten($color-primary, 20%);
  display: inline-block;
  color: $white;
  margin-right: .3rem;
  font-size: 90%;
  padding: .2rem .8rem;
  border-radius: .3rem;
  font-weight: 600;
}
.restform-header a:hover {
  background: lighten($color-primary, 10%);
}
.restform-header a.active {
  background: $color-primary;
}

// nav tabs
.restform-tabs {
  font-size: 96%;
}
.restform-tabs.nav-pills,
.restform-content .tab-pane table {
  font-size: 92%;
}
.restform-tabs.nav-pills .nav-item {
  padding: .4rem .8rem;
  font-weight: 500;
}

// pane contents
.restform-content .tab-pane {
  padding: 1.5rem;
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: $gray-300;
}
.restform-content .tab-pane > table:last-child,
.restform-content .tab-pane > p:last-child,
.restform-content .tab-pane > ul:last-child,
.restform-content .tab-pane > pre:last-child {
  margin-bottom: 0;
}
.restform-content .tab-pane table td {
  border-top: none;
  width: 50%;
}
.restform-content .tab-pane table input {
  width: 100%;
}
.restform-content .tab-pane .restform-input-code {
  color: $pink;
  font-size: 87.5%;
  max-width: 200px;
  width: calc(100% - 40px);
  font-family: $font-family-monospace;
}
@media (min-width: 992px) {
  .restform-content .tab-pane table td:first-child {
    padding-left: 1.5rem;
  }
  .restform-content .tab-pane table td:last-child {
    padding-right: 1.5rem;
  }
}
.restform-content .tab-pane .tab-pane {
  padding: 1.5rem 0 0;
  border: none;
}

// JSON code editor
.restform-content .ace-editor,
.restform-content .ace_editor {
  height: 500px;
}
// https://github.com/ajaxorg/ace/issues/2963
.ace_text-input {
  position: absolute !important;
}
.restform-form,
// .restform-attrs,
.restform-schema {
  max-height: 500px;
  overflow: auto;
}
.restform-attrs {
  overflow-x: hidden;
}

// loading spinner
.restform-loading {
  width: 100%;
  height: 100%;
  background: rgba($black, .6);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
.restform-loading > div {
  padding-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
// Ref.: https://loading.io/css/
.restform-spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.restform-spinner div {
  position: absolute;
  border: 4px solid $white;
  opacity: 1;
  border-radius: 50%;
  animation: restform-spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.restform-spinner div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes restform-spinner {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
